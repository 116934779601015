/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/*
 Use the Tailwind reset instead of the one
 from Ionic because it sets some required
 defaults for Tailwind
*/
@import 'tailwindcss/base';

@import 'theme/fonts.scss';

/* Basic CSS for apps built with Ionic */
// @import '~@ionic/angular/css/structure.css';
// @import '~@ionic/angular/css/typography.css';
// @import '~@ionic/angular/css/display.css';

@import 'tailwindcss/components';

/* Optional CSS utils that can be commented out */
// @import '~@ionic/angular/css/padding.css';
// @import '~@ionic/angular/css/float-elements.css';
// @import '~@ionic/angular/css/text-alignment.css';
// @import '~@ionic/angular/css/text-transformation.css';
// @import '~@ionic/angular/css/flex-utils.css';

@import 'tailwindcss/utilities';

body {
  color: #000;
}

.mvp-generated-paragraphs p {
  margin-bottom: 8px;
}

.mvp-generated-paragraphs a {
  text-decoration: underline;
  color: #2D5DFB;
}
.mvp-generated-paragraphs ul {
  list-style-type: circle;
  padding-left: 20px;
}
.mvp-generated-paragraphs ol {
  list-style-type: decimal;
  padding-left: 20px;
}

lucide-icon.mvp-icon {
  height: 100%;
  width: 100%;
  display: block;
}

lucide-icon.mvp-icon.fill-current {
  fill: currentColor;
}

lucide-icon.mvp-icon.fill-primary-500 {
  fill: #2D5DFB;
}

lucide-icon.mvp-icon.stroke-current svg {
  stroke: currentColor;
}

lucide-icon.mvp-icon.stroke-primary-500 svg {
  stroke: #2D5DFB;
}

lucide-icon.mvp-icon.hidden {
  display: none;
}

.card-shadow {
  box-shadow: 0px 35px 30px -32px rgba(0, 25, 250, 0.228);
}

.card-shadow-tract {
  box-shadow: 0px 35px 30px -32px rgba(206, 130, 0, 0.386)
}

html {
  background-color: white;
}

// ::-webkit-scrollbar {
//   display: none;
// }

.ql-indent-1 {
  margin-left: 1rem;
}

.ql-indent-2 {
  margin-left: 2rem;
}

.ql-indent-3 {
  margin-left: 3rem;
}
