/*
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: url(../assets/fonts/SourceSansPro/Source-Sans-Pro-italic-300.ttf) format('truetype'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-italic-300.woff) format('woff'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-italic-300.woff2) format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url(../assets/fonts/SourceSansPro/Source-Sans-Pro-italic-400.ttf) format('truetype'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-italic-400.woff) format('woff'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-italic-400.woff2) format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: url(../assets/fonts/SourceSansPro/Source-Sans-Pro-italic-600.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: url(../assets/fonts/SourceSansPro/Source-Sans-Pro-italic-700.ttf) format('truetype'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-italic-700.woff) format('woff'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-italic-700.woff2) format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  src: url(../assets/fonts/SourceSansPro/Source-Sans-Pro-italic-900.ttf) format('truetype'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-italic-900.woff) format('woff'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-italic-900.woff2) format('woff2');
}
*/

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url(../assets/fonts/SourceSansPro/Source-Sans-Pro-normal-300.ttf) format('truetype'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-normal-300.woff) format('woff'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-normal-300.woff2) format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/SourceSansPro/Source-Sans-Pro-normal-400.eot);
  src: local('Source Sans Pro'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-normal-400.ttf) format('truetype'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-normal-400.woff) format('woff'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-normal-400.eot?#iefix) format('embedded-opentype'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-normal-400.woff2) format('woff2'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-normal-400.svg#SourceSansPro) format('svg');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url(../assets/fonts/SourceSansPro/Source-Sans-Pro-normal-600.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url(../assets/fonts/SourceSansPro/Source-Sans-Pro-normal-700.ttf) format('truetype'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-normal-700.woff) format('woff'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-normal-700.woff2) format('woff2');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: url(../assets/fonts/SourceSansPro/Source-Sans-Pro-normal-900.ttf) format('truetype'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-normal-900.woff) format('woff'), url(../assets/fonts/SourceSansPro/Source-Sans-Pro-normal-900.woff2) format('woff2');
}

